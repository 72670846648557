/* eslint-disable no-irregular-whitespace */
import React from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textWrapper: {
      display: `flex`,
      flexDirection: `column`,
      justifyContent: `center`,
      alignItems: `center`,
      margin: `1rem auto`,
      gap: `1rem`,
    },
    sign: {
      fontWeight: 600,
    },
  }),
);

const MessageContents: React.VFC = () => {
  const classes = useStyles();

  return (
    <div className={classes.textWrapper}>
      <Typography>
        伝承。そして新たな飛躍へ。
        <br />
        日本は「失われた３０年」を経ても尚、他のOECD諸国と比べても平均給与が伸びない割に税負担は増えて、手取り給与は下がり続けている。このまま改革を断行しなければ、我が国に明るい未来はない。
        <br />
        日本維新の会は、「身を切る改革＝議員報酬の２～３割削減」を実行し、いかなる企業・組合・宗教団体から寄附・献金や支援を受けず、「しがらみのない政治」を実践し、既得権益と対峙し、改革を推し進める事が出来る唯一の政党です。
      </Typography>
      <Typography>
        2023年４月の統一地方選挙では、東京で７４名の立候補者を擁立し、７１名当選させて戴きました。練馬区でも練馬区議会議員選挙に３人立候補し、全員当選させて戴きました。
        <br />
        これは、大阪でやって来た「維新の改革」が評価され、全国各地に伝搬して来ており、各地にいらっしゃる「維新応援団」に後押しして戴いた結果だと感謝しております。
      </Typography>
      <Typography>
        2022末に衆議院小選挙区の区割り変更が国会で可決し即施行され、私の選挙区は『衆議院東京都第２８選挙区』に変更となりました。選挙区は練馬区の東半分（ほぼ笹目通りの東側）で、殆どの地域は旧東京１０区と重なりますので、引き続きご指導・ご鞭撻のほど、よろしくお願い申し上げます。
      </Typography>
      <Typography className={classes.sign} align="right">
        東京第28選挙区支部長　藤川たかし
      </Typography>
    </div>
  );
};

export default MessageContents;
